<template>
  <span
    class="text-primary flex flex-col items-center font-semibold leading-none"
  >
    <span class="text-4xl">
      <img :src="logoUrl" alt="Mark" class="h-12 sm:h-16 w-36" />
    </span>
  </span>
</template>
<script setup lang="ts">
const props = defineProps<{
  logoUrl?: string;
}>();

const logoUrl =
  props.logoUrl ||
  "https://res.cloudinary.com/ds9ewjix6/image/upload/v1724692120/LOGOS/stackimo_ghg6yw.svg";
</script>
